<template lang="pug">
  .tab-main-container
    .headline チェックイン履歴
    .text-center
      b-spinner.mt-5.mb-5(variant="primary" label="Loading..." v-if="isLoading")
    h6.form-group.pl-2.pt-2(v-if="isCheckinRecord")
      h5.pl-1.pt-2 これまでのチェックイン履歴
      .form-check
        input#checkinAll.form-check-input(type="radio" v-on:change="checkinKind" v-model="checkin" name="checkin" value="checkinAll" required)
        label.form-check-label.radio-label(for="checkinAll") 全て表示
      .form-check
        input#checkinRocky.form-check-input(type="radio" v-on:change="checkinKind" v-model="checkin" name="checkin" value="checkinRocky" required)
        label.form-check-label.radio-label(for="checkinRocky") 岩場チェックインのみ表示
      .form-check
        input#checkinRock.form-check-input(type="radio"  v-on:change="checkinKind" v-model="checkin" name="checkin" value="checkinRock" required)
        label.form-check-label.radio-label(for="checkinRock") 岩チェックインのみ表示
    div(v-if="!isLoading")
      #search-results(v-if="data.length == 0") {{ this.message }}
      .rock-table-wrapper(v-else)
        .rock-table
          table.table.table
            thead.thead-dark.small
              tr
              th.align-middle(scope="col") 日時
              th.align-middle(scope="col") 岩場
              th.align-middle(scope="col") 岩
            tbody
              tr.table-active.small(v-for="item in pageData")
                td.align-middle {{ item.created.slice(0, 16).replace(/-/g, '/').replace(/T/g, ' ')  }}
                td.align-middle {{ item.rockyName }}
                td.align-middle {{ item.rockName }}
        .mb-5
          nav
            ul.pagination.justify-content-center
              li.page-item(:class="{disabled: page == 1}")
                button.page-link(@click.prevent="page--" :disabled="page == 1") ≺ 前へ
              li.page-item.disabled
                a.page-link ページ {{ page }} / {{ lastPage }}
              li.page-item(:class="{disabled: page == lastPage}")
                button.page-link(@click.prevent="page++" :disabled="page == lastPage") 次へ ≻
</template>

<script>
import axios from 'axios'

// 1ページの表示件数
const PAR_PAGE = process.env.VUE_APP_PAR_PAGE

export default {
  // データオブジェクト
  data() {
    return {
      checkin: 'checkinAll',
      checkinData: null,
      data: [],
      isRock: true,
      rockyId: this.$route.params.rockyId,
      page: 1,
      lastPage: 1,
      isLoading: true,
      isCheckinRecord: false,
      message: '',
    }
  },
  created() {
    this.getCheckinHistory()
  },
  // メソッド
  methods: {
    getCheckinHistory() {
      //チェックイン履歴の取得
      axios.get(process.env.VUE_APP_API_GATEWAY + '/api-checkin', {
        params: {
          "rockyId": this.rockyId,
          "targetUserId": this.$route.params.userId
        },
        headers: {
          'Authorization': this.$user.isLogin ? this.$user.idToken : 'no-login'
        }
      })
        .then(async res => {
          this.checkinData = res.data
          if(res.data == null) {
            //チェックイン履歴とれなかった場合
            this.message = '非公開'
          }else if(res.data.length == 0) {
            //チェックイン履歴0件の場合
            this.message = 'チェックイン履歴がありません'
          }else if(res.data.length > 0){
            //チェックイン履歴ある場合
            this.checkinKind()
          }
          this.isLoading = false
        })
        .catch(err => {
          this.isLoading = false
          this.message = '情報の取得に失敗しました'
          return
        })
    },
    checkinKind() {
      if(this.checkin == 'checkinAll') {
        //全て表示の場合
        this.data = this.checkinData
      }else if(this.checkin == 'checkinRocky') {
        //岩場のみの場合
        this.data = []    
        for(let i=0; i<this.checkinData.length;i++) {
          if(this.checkinData[i].rockId == 0) {
            this.data.push(this.checkinData[i])
          }
        }
      }else if(this.checkin == 'checkinRock') {
        //岩のみの場合
        this.data = []
        for(let i=0; i<this.checkinData.length;i++) {
          if(this.checkinData[i].rockId != 0) {
            this.data.push(this.checkinData[i])
          }
        }
      }
      this.isCheckinRecord = true
      this.page = 1
      this.lastPage = this.data.length / PAR_PAGE
      this.lastPage = Math.ceil(this.lastPage)
    },
  },
  // 算出プロパティ
  computed: {
    pageData() {
      return this.data.slice((this.page-1) * PAR_PAGE, this.page * PAR_PAGE)
    }
  },
  // コンポーネント
  components: {
  }
}
</script>

<style lang="stylus" scoped>
#search-results
  margin 1rem
</style>
